var currentLanguageShort = typeof _spPageContextInfo != 'undefined' ? _spPageContextInfo.currentCultureName.split('-')[0] : (function() {
    var lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
    if (lang.indexOf('-') !== -1)
        lang = lang.split('-')[0];

    if (lang.indexOf('_') !== -1)
        lang = lang.split('_')[0];
    return lang;
})();

if (!String.prototype.replaceRange)
Object.defineProperty(String.prototype, 'replaceRange', {
    enumerable: false,
    value: function (start, end, substitute) {
        return this.substring(0, start) + substitute + this.substring(end);;
    }
});
if (!String.prototype.format) {
    Object.defineProperty(String.prototype, 'format', {
        enumerable: false,
        value: function() {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function(match, number) { 
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
            });
        }
    });
}

var nonLocalizedKeys   = {};

if ( typeof NonTranslatedWords != 'undefined' ) {
    var nonTranslatedWords = new NonTranslatedWords(currentLanguageShort);
}

Object.defineProperties(String.prototype, {
    translated: {
        enumerable: false,
        get: function () {
            var ret = this[currentLanguageShort];
            return typeof ret == 'string' ? ret : this;
        }
    }
});

for ( var i in lstrings ) {
    Object.defineProperty(String.prototype, i, (function(key) {
        return {
            enumarable: false,
            get: function () {
                var ret = lstrings[key][this];
                if ( typeof ret == 'undefined' && typeof nonLocalizedKeys[this] == 'undefined' ) {
                    nonLocalizedKeys[this] = this;
                    if ( typeof NonTranslatedWords != 'undefined' ) {
                        nonTranslatedWords.test(this);
                    }
                }
                return typeof ret != 'undefined' ? ret : this;
            }
        }
    })(i))
}

(function($) {

    function callback () {
        $('[data-translated], .translated, [translated]').each(function ( index, item ) {
            var qitem = $(item);
            var key = '';

            if ( item.hasAttribute('data-translated') && qitem.data('translated').trim() != '' ) {
                key = qitem.data('translated');
            } else if ( item.hasAttribute('translated') && qitem.attr('translated').trim() != '' ) {
                key = qitem.attr('translated');
            } else if ( qitem.hasClass('translated') ) {
                key = qitem.text().trim();
            }

            qitem.text( key.translated );

            qitem.removeAttr('data-translated');
            qitem.removeClass('translated');
            qitem.removeAttr('translated');
        });

        $('[data-translated-ife').each(function ( index, item ) {
            var qitem = $(item);
            var key = qitem.data('translated-ife');
            if ( qitem.text().trim() == '' ) {
                qitem.text( key.translated );
            }
            qitem.removeAttr('data-translated-ife');
        });
    }
    window.lcb = callback;

    callback(); // Instant call
    $(".main-container").bind("DOMSubtreeModified", callback); // When Dom Modified
    $(document).ready(callback);
    $(window).on('load', callback);


}).apply(window, [jQuery]);